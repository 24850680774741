import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button, AutoComplete } from "antd";
import Layout from "../components/layout";
import axios from "axios";
import SweetAlert from "sweetalert-react";
import Pagination from "react-js-pagination";
import LetsChat from "../components/letsChat";
// import tick from "../images/tick icon.png";
require("bootstrap/dist/css/bootstrap.css");
require("sweetalert/dist/sweetalert.css");
require("../css/business.css");
// require("../css/responsive.scss"); // otherButtons

// var businessProducts = [];
var datasource = [];
var tick =
  "https://res.cloudinary.com/djoztpm50/image/upload/v1565276218/tick_icon_d1yrmm-min_enpjqp.png";
class ForBusiness extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
      visible: false,
      loading: false,
      productName: "",
      displayName: "none",
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPhone: "none",
      displayPhoneInvalid: "none",
      displayOrg: "none",
      displayQty: "none",
      activePage: 1,
      itemLength: 0,
      arrayBusinessProducts: [],
      diffCategory: [],
      showDiv: false,
      businessProducts: [],
      ProductsQuantity: {
        products: "",
        quantity: 0
      },
      shareholders: [],
      name: "",
      phone: "",
      email: "",
      organisation: "",
      disabled: ""
    };
    // this.filterByCategory =this.filterByCategory.bind(this);
  }

  componentDidMount() {
    datasource.length = 0;

    var productTypeArr = [];
    var data = this.props.data.allWordpressWpBusinessproduct.edges;
    // console.log("data", data);

    var len = data.length;
    this.setState({ itemLength: len });

    function arrayTo2DArray2(list, howMany) {
      var idx = 0;
      var result = [];

      while (idx < list.length) {
        if (idx % howMany === 0) result.push([]);
        result[result.length - 1].push(list[idx++]);
      }
      return result;
    }

    // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
    var d = arrayTo2DArray2(data, 12);
    this.setState({ businessProducts: d[0], arrayBusinessProducts: d }, () => {
      // console.log("this", this.state.arrayBusinessProducts);
    });

    data.map(obj => {
      // console.log("type", obj.node.acf.product_type)
      productTypeArr.push(obj.node.acf.product_type);

      datasource.push(obj.node.title);
    });

    // console.log("datasource", typeof datasource);
    //Remove duplicate from array
    var nodup = productTypeArr.filter(
      (elem, index, self) =>
        self.findIndex(obj => {
          return obj === elem;
        }) === index
    );

    // console.log("nodup", nodup)
    this.setState({ diffCategory: nodup }, () => {
      // console.log("diffCategory", this.state.diffCategory);
    });
  }
  //---------------------------------------------------------------
  handleAddShareholder = () => {
    this.setState({
      shareholders: this.state.shareholders.concat("")
    });
  };

  handleRemoveShareholder = idx => () => {
    // console.log(
    //   "handleRemoveShareholder",
    //   idx,
    //   "shareholders",
    //   this.state.shareholders
    // );

    // var deletedData = this.state.shareholders[idx];

    let newShare = this.state.shareholders.filter((s, sidx) => idx != sidx);
    this.setState({
      shareholders: newShare,
      disabled: ""
    });
    // this.props.onChange(newShare);
  };

  onChangeVendorPecentage(key, value, idx) {
    // console.log("inside nscjnsjnc", key, "value", value, "idx", idx);
    var dummy = this.state.shareholders[idx];

    console.log("dummy", dummy, key, value, idx);

    if (key == "products") {
      var alphaOnly = value.replace(/[^a-zA-Z\.]+/g, "");
      if (dummy == "") {
        var emptyObj = {};
        emptyObj.products = alphaOnly;
        dummy = emptyObj;
      } else dummy.products = alphaOnly;
    } else if (key == "quantity") {
      var numberOnly = value.replace(/[^0-9\.]+/g, "");
      if (dummy == "") {
        var emptyObj = {};
        emptyObj.quantity = numberOnly;
        dummy = emptyObj;
      } else dummy.quantity = numberOnly;
    }

    this.setState(
      {
        ProductsQuantity: dummy
      },

      // this.setState(
      //   {
      //     vendorPercentage: {
      //       ...this.state.vendorPercentage,
      //       [key]: value
      //     }
      //   },
      () => {
        // console.log("this.state.vendorPercentage", this.state.vendorPercentage);
        this.getVendorPercent(idx);

        // console.log("ProductsQuantity", this.state.ProductsQuantity);
      }
    );
  }

  getVendorPercent(idx) {
    // console.log("vendor", this.state.ProductsQuantity.products);
    // console.log(
    //   "percentage",
    //   this.state.ProductsQuantity.quantity,
    //   this.state.shareholders
    // );

    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      var getVendorId = this.state.ProductsQuantity.products;
      var getVendorId1 = this.state.ProductsQuantity;
      console.log("this getVendorId", getVendorId1);

      if (getVendorId != undefined) {
        getVendorId = getVendorId.split("$@$")[1];
        this.setState({
          disabled: ""
        });
      } else {
        this.setState({
          disabled: "disabled"
        });
      }
      // console.log("getVendorId : " + getVendorId);
      var obj = {};
      obj["products"] = this.state.ProductsQuantity.products;
      obj["quantity"] = this.state.ProductsQuantity.quantity;

      return obj;
    });

    // console.log("newShareholders", newShareholders);
    /* Remove Duplicate from array of Objects */
    var nodup = newShareholders.filter(
      (elem, index, self) =>
        self.findIndex(obj => {
          return obj.products === elem.products;
        }) === index
    );
    // console.log("nodup", nodup);

    this.setState(
      { shareholders: nodup }
      // () =>      console.log("this.state.shareholders", this.state.shareholders)
    );

    // this.props.onChange(newShareholders);
  }

  //---------------------------------------------------------------

  handlePageChange(pageNumber) {
    window.location.href = "#bulkProducts";
    var _this = this;
    _this.setState({
      activePage: pageNumber,
      businessProducts: this.state.arrayBusinessProducts[pageNumber - 1]
    });
  }

  showModal(getproductName, getQuantity) {
    // alert("inside showmodal")
    this.setState(
      {
        visible: true,
        productName: getproductName,
        shareholders: [
          {
            products: getproductName,
            quantity: 0
          }
        ]
      },
      () => {
        // console.log("ProductsQuantity", this.state.ProductsQuantity);
      }
    );
  }

  handleOk() {
    var _this = this;
    var bodyFormData = new FormData();

    // var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var phone = document.getElementById("number").value;
    var phoneLength = document.getElementById("number").value.length;
    // var quantity = "2";
    // var organisation = document.getElementById("org").value;
    var message = document.getElementById("comment").value;
    // var prodName = "raj";
    var productQuantity = this.state.shareholders;

    // console.log("productQuantityp", productQuantity);

    var stringify;
    stringify = JSON.stringify(productQuantity);
    // console.log("stringify", stringify);

    if (this.state.name == "") {
      this.validateName();
    } else if (this.state.phone == "" || phoneLength < 10) {
      this.validatePhone();
    } else if (this.state.email == "" || !pattern.test(email)) {
      this.validateEmail();
    }
    // else if (quantity == "") {
    //   this.validateQuantity();
    // }
    else if (this.state.organisation == "") {
      this.validateOrganisation();
    } else {
      bodyFormData.set("fullName", this.state.name);
      bodyFormData.set("email", this.state.email);
      bodyFormData.set("number", this.state.phone);
      bodyFormData.set("prodQuantity", stringify);
      bodyFormData.set("organisation", this.state.organisation);
      bodyFormData.set("message", message);
      // bodyFormData.set("ProductName", prodName);
      bodyFormData.set("formID", 190);
      // console.log(_this);
      axios
        .post(
          "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/190/feedback",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          console.log(response.status);
          // document.getElementById("form").reset();
          if (response.status == 200 || response.status == 201) {
            _this.setState({
              showAlert: true,
              loading: false,
              name: "",
              phone: "",
              email: "",
              organisation: ""
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  filterByCategory(value) {
    // console.log("filterByCategory value", value)
    var data = this.props.data.allWordpressWpBusinessproduct.edges;
    var selectedCategory = data.filter(function (obj) {
      return obj.node.acf.product_type == value;
    });
    // console.log("selectedCategory", selectedCategory);

    // set pagination length
    var len = selectedCategory.length;
    this.setState({ itemLength: len });

    // this.setState({ businessProducts: selectedCategory })

    function arrayTo2DArray2(list, howMany) {
      var idx = 0;
      var result = [];

      while (idx < list.length) {
        if (idx % howMany === 0) result.push([]);
        result[result.length - 1].push(list[idx++]);
      }
      return result;
    }

    // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
    var d = arrayTo2DArray2(selectedCategory, 12);
    this.setState({ businessProducts: d[0], arrayBusinessProducts: d }, () => {
      // console.log("this", this.state.arrayBusinessProducts);
    });
  }

  validateName() {
    var name = document
      .getElementById("name")
      .value.replace(/[^a-zA-Z\s]/g, "");
    // console.log("name", name)
    if (name == "" || name == undefined) {
      this.setState({ displayName: "block", name: "" });
    } else {
      this.setState({ displayName: "none", name: name });
    }
  }

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "" || email == undefined) {
      this.setState({ displayEmail: "block", email: "" });
    } else {
      this.setState({ displayEmail: "none", email: email });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none", email: email });
      }
    }
  }

  validatePhone() {
    var phone = document.getElementById("number").value;
    var phonePattern = /^\d+$/.test(phone);
    // var phoneValue = document.getElementById("number").value.replace(/[^\d]/,'');

    if (phonePattern == true) {
      var phoneLength = document.getElementById("number").value.length;
      if (phone == "" || phone == undefined) {
        this.setState({ displayPhone: "block", phone: "" });
      } else {
        this.setState({ displayPhone: "none", phone: phone });
        // console.log("phonePattern.test(phone)", phonePattern.test(phone));
        if (phoneLength < 10) {
          this.setState({ displayPhoneInvalid: "block" });
        } else {
          this.setState({ displayPhoneInvalid: "none" });
        }
      }
    } else {
      this.setState({ displayPhone: "block", phone: "" });
    }
  }

  // validateQuantity() {
  //   var quantity = document.getElementById("quantity").value;
  //   if (quantity == "") {
  //     this.setState({ displayQty: "block" });
  //   } else {
  //     this.setState({ displayQty: "none" });
  //   }
  // }
  validateOrganisation() {
    var organisation = document.getElementById("org").value;
    if (organisation == "") {
      this.setState({ displayOrg: "block", organisation: "" });
    } else {
      this.setState({ displayOrg: "none", organisation: organisation });
    }
  }

  collapseIcon() {
    this.setState({ showDiv: false });
  }
  expandIcon() {
    this.setState({ showDiv: true });
  }

  render() {
    var today = new Date();
    const {
      visible,
      loading,
      businessProducts,
      productName,
      itemLength,
      diffCategory,
      ProductsQuantity
    } = this.state;

    return (
      <div>
        <SweetAlert
          show={this.state.showAlert}
          title="Thank you for contacting us!"
          text="We will get in touch with you soon."
          onConfirm={() => this.setState({ showAlert: false, visible: false })}
        />
        <Modal
          visible={visible}
          title="Enquire For Bulk Orders"
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          maskClosable={false}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              Cancel
            </Button>,
            <Button
              className="BusinessFormBtn"
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => this.handleOk()}
            >
              Submit
            </Button>
          ]}
        >
          <form action="javascript:void(0)" id="form">
            <div className="row form-group">
              <div className="col-md-12 col-xs-12 col-sm-12 ">
                <label htmlFor="name" className="NC-formlabel">
                  Full name
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="text"
                  id="name"
                  required
                  value={this.state.name}
                  onChange={() => this.validateName()}
                />
                <p
                  style={{
                    display: this.state.displayName,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="number" className="NC-formlabel">
                  Mobile number
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="text"
                  id="number"
                  maxlength="10"
                  size="10"
                  pattern="\d{10}"
                  required
                  value={this.state.phone}
                  onChange={() => this.validatePhone()}
                />
                <p
                  style={{
                    display: this.state.displayPhone,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
                <p
                  style={{
                    display: this.state.displayPhoneInvalid,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  Invalid mobile number
                </p>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="email" className="NC-formlabel">
                  Email ID
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="email"
                  id="email"
                  value={this.state.email}
                  required
                  onChange={() => this.validateEmail()}
                />
                <p
                  style={{
                    display: this.state.displayEmail,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>

                <p
                  style={{
                    display: this.state.displayEmailInvalid,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  The entered email id is not valid
                </p>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="prodName" className="NC-formlabel">
                  Product & Quantity
                </label>
                {/*     <input
                  className="form-control NC-fullWidth"
                  type="text"
                  value={productName}
                  id="prodName"
                  readOnly
                /> */}

                {/*   <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="quantity" className="NC-formlabel">
                  Quantity
                </label>
                <input
                  className="form-control NC-fullWidth quantityfield formcontrolColorChange"
                  type="number"
                  id="quantity"
                  required
                  onInput={() => this.validateQuantity()}
                />
                <p
                  style={{
                    display: this.state.displayQty,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
              </div> */}

                {this.state.shareholders.map((shareholder, idx) => (
                  <div className="shareholder" key={idx}>
                    <AutoComplete
                      style={{ width: "60%", marginRight: "12px" }}
                      dataSource={datasource}
                      placeholder="Products"
                      defaultOpen={false}
                      // defaultValue={ProductsQuantity.products}
                      value={shareholder.products}
                      onChange={e =>
                        this.onChangeVendorPecentage("products", e, idx)
                      }
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />

                    <input
                      className="ant-input"
                      style={{ width: "20%", marginRight: "12px" }}
                      type="text"
                      placeholder="Quantity"
                      value={shareholder.quantity}
                      onChange={e =>
                        this.onChangeVendorPecentage(
                          "quantity",
                          e.target.value,
                          idx
                        )
                      }
                    />

                    <button
                      type="button"
                      onClick={this.handleRemoveShareholder(idx)}
                      className="ant-btn"
                    >
                      <i className="fa fa-times" />
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={this.handleAddShareholder}
                  disabled={this.state.disabled}
                  className="ant-btn BusinessFormBtn ant-btn-primary"
                >
                  Add Products
                </button>
              </div>
              {/*----------------------------------------------------------------------------------  */}

              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="org" className="NC-formlabel">
                  Organization
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="text"
                  id="org"
                  required
                  value={this.state.organisation}
                  onChange={() => this.validateOrganisation()}
                />
                <p
                  style={{
                    display: this.state.displayOrg,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="comment" className="NC-formlabel">
                  Any queries / Comments
                </label>
                <textarea
                  className="form-control formcontrolColorChange"
                  rows="5"
                  id="comment"
                />
              </div>
            </div>
          </form>
        </Modal>

        <Layout>
          <div className="scrolling-box">
            <div className="forBusinessBanner">
              <div className="col-sm-12">
                <h3 className="businessHeading">
                  Let’s co-create organic societies <br />
                  through business
                </h3>
                <div className="col-sm-12 bulkOrderBtn">
                  <a href="#bulkProducts">
                    <button className="homeBtn">
                      Place bulk orders{" "}
                      <img
                        className="iconCheckout"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="row bulkProdDiv">
              <div
                id="bulkProducts"
                className="row col-md-12 productCatMainDiv"
              >
                <div className="col-md-12 col-xs-12 cd-sm-12">
                  <h3 className="NC-vision-title">
                    <span className="NC-vision-span">
                      Your perfect wholesale/retail stop{" "}
                    </span>
                    for organic products.
                  </h3>
                  <div className="NC-vision-desc">
                    <p className="">
                      Raw products. Semi-Raw products. Value added Products.
                      Certified sources, balanced rates.
                    </p>

                    <div className="row">
                      {this.state.showDiv ? (
                        <div
                          id="mobileFilterId"
                          className="col-xs-12 col-md-12 col-sm-12"
                        >
                          <a
                            className="NC-collapse-icon"
                            onClick={() => this.collapseIcon()}
                          >
                            <div className="col-xs-12 col-md-12 col-sm-12 Filterborder">
                              <p className="pFilter">Filter</p>
                              <img
                                className="NC-collapse-img-rightProductPage"
                                src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860745/Native%20Circle/group-44.svg"
                              />
                            </div>
                          </a>
                          {/* <div className="col-md-2 filterCategory"> */}
                          <div className="filterCategory">
                            <p className="filterTxt">Filter By Type</p>
                            {diffCategory.map((category, i) => {
                              return (
                                <div>
                                  <input
                                    type="radio"
                                    value={category}
                                    name="category"
                                    key={i}
                                    onChange={() =>
                                      this.filterByCategory(category)
                                    }
                                    className="business-radio-custom"
                                    id={i}
                                  />
                                  <label
                                    className="business-radio-custom-label"
                                    for={i}
                                  >
                                    {category}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                          <div
                            id="mobileFilterId"
                            className="col-xs-12 col-md-12 col-sm-12"
                          >
                            <a
                              className="NC-collapse-icon"
                              onClick={() => this.expandIcon()}
                            >
                              <div className="col-xs-12 col-md-12 col-sm-12 Filterborder">
                                <p className="pFilter">Filter</p>
                                <img
                                  className="NC-collapse-img-rightProductPage"
                                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1552297841/Native%20Circle/group-129.svg"
                                />
                              </div>
                            </a>
                          </div>
                        )}
                      <div
                        id="desktopFilterId"
                        className="col-md-2 filterCategory"
                      >
                        <p className="filterTxt">Filter By Type</p>
                        {diffCategory.map((category, i) => {
                          return (
                            <div>
                              <input
                                type="radio"
                                value={category}
                                name="category"
                                key={i}
                                onChange={() => this.filterByCategory(category)}
                                className="business-radio-custom"
                                id={i}
                              />
                              <label
                                className="addSpace business-radio-custom-label"
                                for={i}
                              >
                                {category}
                              </label>
                            </div>
                          );
                        })}
                      </div>

                      {/* <div className="row bulkProdRow"> */}
                      <div className="col-md-10">
                        <div className="row  bulkProdRow">
                          {businessProducts.map(e => {
                            // this.returnProductDiv(product)
                            return (
                              <div className="col-sm-6 col-12 col-md-3 ">
                                <div className="productDiv">
                                  <div className="productlistimg">
                                    <img
                                      src={
                                        e.node.acf.images == null
                                          ? "https://dashboard.nativecircle.in/wp-content/uploads/2019/03/dummy.png"
                                          : e.node.acf.images.source_url
                                      }
                                      className="prodImg img-fluid"
                                      alt="Responsive image"
                                    />
                                  </div>

                                  <div className="prodPad">
                                    <div className="businessProdName">
                                      <p>{e.node.title}</p>
                                    </div>
                                    <p className="businessProdDesc">
                                      Min Quantity:{" "}
                                      <span> {e.node.acf.unit} </span>
                                    </p>
                                    <p className="businessProdDesc">
                                      Price:{" "}
                                      <span>
                                        &#8377; {e.node.acf.native_price}
                                      </span>
                                    </p>
                                    <p className="businessProdDesc type">
                                      Type:{" "}
                                      <span> {e.node.acf.product_type} </span>
                                    </p>
                                    <button
                                      className="enquireBtn"
                                      onClick={() =>
                                        this.showModal(e.node.title)
                                      }
                                    >
                                      <p> Enquire </p>
                                      <img
                                        className="enqCheckoutIcon"
                                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* </div> */}
                        <div className="paginate">
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={12}
                            totalItemsCount={itemLength}
                            pageRangeDisplayed={5}
                            disabledClass="disabled"
                            activeLinkClass="page-link"
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row NC-whoWeAre">
              <div className="col-md-12 col-xs-12 cd-sm-12">
                <h3 className="NC-whoWeAre-title">
                  Who <span className="NC-whoWeAre-span">we</span> are{" "}
                </h3>
                <div className="NC-whoWeAre-titledesc">
                  <p className="">
                    Native circle is a conscious effort to go back to the
                    original values and traditions which once shaped the value
                    system of India.
                  </p>
                  <p className="">
                    Espousing the natural way of life and living, Native Circle
                    aims to integrate dispersed expertise, successful practices
                    and its own experimental results
                  </p>
                  <p className="">
                    under one roof to develop an instructive model. Nature
                    education, agriculture, forestry, food, and value-added
                    products being the areas of focus.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-xs-12 NC-whoWeAre-tile">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551190683/Native%20Circle/attractive-beautiful-beauty-415829-copy-3_tgsnop.png" />
                    <p className="NC-whoWeAre-Name">Mahesh</p>
                    <p className="NC-whoWeAre-desc">
                      Over 12 years of experience in brand management and 3
                      years in social impact space. MBA.
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xs-12 NC-whoWeAre-tile">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551190683/Native%20Circle/attractive-beautiful-beauty-415829-copy-3_tgsnop.png" />
                    <p className="NC-whoWeAre-Name">Swayamprabha</p>
                    <p className="NC-whoWeAre-desc">
                      Over 4 years of experience in communication and social
                      change space, B.Sc in Computer Science{" "}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xs-12 NC-whoWeAre-tile">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551190683/Native%20Circle/attractive-beautiful-beauty-415829-copy-3_tgsnop.png" />
                    <p className="NC-whoWeAre-Name">Maitreyi</p>
                    <p className="NC-whoWeAre-desc">
                      Over 2 years experience in the social impact space and 1
                      year experience in technology product development.
                    </p>
                  </div>
                   <div className="col-sm-6 col-md-3 col-xs-12 NC-whoWeAre-tile">
                  <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551190683/Native%20Circle/attractive-beautiful-beauty-415829-copy-3_tgsnop.png" />
                  <p className="NC-whoWeAre-Name">Swayamprabha</p>
                  <p className="NC-whoWeAre-desc">
                    Over 3 years of experience in sustainable product research,
                    M.sc in Biotechnology.
                  </p>
                </div>
                </div>
              </div>
            </div> */}

            <div className="container NC-business-wwb">
              <div className="row">
                <div className="NC-business-wwb-text col-md-12 col-xs-12 col-sm-12">
                  <h3 className="NC-whoWeAre-title">
                    Our<span className="NC-whoWeAre-span"> values</span>{" "}
                  </h3>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className="NC-business-wwb-tile">
                    <img
                      className="NC-wwb-icon"
                      // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                      src={tick}
                    />
                    <p className="NC-wwb-icontext">Transparency</p>
                    <p className="NC-wwb-desc">
                      Ethical Harvesting. Responsible Value Addition. Reasonable
                      market rates.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className="NC-business-wwb-tile">
                    <img
                      className="NC-wwb-icon"
                      // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                      src={tick}
                    />
                    <p className="NC-wwb-icontext">Ecofriendly</p>
                    <p className="NC-wwb-desc">
                      Natural produces. Nature friendly. Degradable packaging.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className="NC-business-wwb-tile">
                    <img
                      className="NC-wwb-icon"
                      // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                      src={tick}
                    />
                    <p className="NC-wwb-icontext">Synergy</p>
                    <p className="NC-wwb-desc">
                      Inclusive progress.Development for all. Symbiotic
                      developments.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className="NC-business-wwb-tile">
                    <img
                      className="NC-wwb-icon"
                      // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                      src={tick}
                    />
                    <p className="NC-wwb-icontext">Purity</p>
                    <p className="NC-wwb-desc">
                      Pure produces. Traditional processes. Healthy food.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr className="NC-separator" />

            <LetsChat />
          </div>
        </Layout>
      </div>
    );
  }
}
export default ForBusiness;

export const pageQuery = graphql`
  query businessProductQuery {
    allWordpressWpBusinessproduct(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          acf {
            product_type
            type
            unit
            native_price
            images {
              source_url
            } 
          }
        }
      }
    }
  }
`;

